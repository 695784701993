
import { computed, defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import AddOrganization from "@/modules/organization/AddOrganization.vue";

import { useStore } from "vuex";

export default defineComponent({
  name: "OrganizationsPage",
  components: {
    AddOrganization,
  },
  setup() {
    const store = useStore();

    const organizations = computed(() => {
      return store.getters.allOrganizations;
    });

    onMounted(async () => {
      setCurrentPageTitle("Organizations");
    });

    return {
      organizations,
    };
  },
});
