
import { defineComponent, reactive } from "vue";
import ApiService from "@/core/services/ApiService";
import { Organization } from "./organization.model";
import { useStore } from "vuex";
import { processErrors } from "@/api/errorsProcessing";

export default defineComponent({
  name: "addorganization",
  components: {},
  setup() {
    const store = useStore();

    let newOrg = {
      id: "",
      name: "",
      connectionString: "",
      adminEmail: "",
      issuer: "",
      emrLinkId: "",
    };

    let organization = reactive<Organization>(newOrg);

    async function addOrganization() {
      await ApiService.post("api/tenants", newOrg as never)
        .then(async (): Promise<void> => {
          organization.id = "";
          organization.name = "";
          organization.connectionString = "";
          organization.adminEmail = "";
          organization.issuer = "";
          organization.emrLinkId= "";
          await store.dispatch("GETORGANIZATIONS");
        })
        .catch(({ response }) => {
          processErrors(response);
        });
    }

    return {
      organization,
      addOrganization,
    };
  },
});
